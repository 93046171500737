import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import GraphicDesignServiceBanner from '../images/graphic_service_banner.svg'
import GraphicDesignServiceFeature from '../images/graphic_design_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const GraphicDesign = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/graphics-design-services" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Graphic Design Services" description='eWise - Graphic Design Services'/>
<section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90 block">Graphics</strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-32 xl:text-34 2xl:text-46">Design Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-500 md:max-w-500 lg:max-w-610 m-auto lazyload" loading="lazy" data-src={GraphicDesignServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">Get noticed with the world-className brand design<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p>Visual aspects of branding are quintessential in both online marketing campaigns and print marketing. eWise can help you to market your brand messaging with a unique design! We count on a team of professional designers to offer the full range of graphic design services to our clients.</p>
            </div>
          </div>
      </div>
  </section>
  <section className="relative pb-8 sm:pb-10 lg:pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-1/2 px-4 order-2 lg:order-1">
                   
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why do brands need a design <br className="hidden xl:inline-block"/> service!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get noticed with a unique design for your brand</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Create your brand personality that mirrors your brand messaging</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Create the coherency in your every branding and marketing asset</li>
                        <li className="pl-10 relative">Make the difference in your prospects mind with good design</li>
                    </ul>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-330 md:max-w-330 xl:max-w-420 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={GraphicDesignServiceFeature} alt="iphone"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise enables you to set your brand apart from the crowd!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Mobile App Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Drive high customer engagement to your mobile apps by designing interactive user interfaces, user experiences & visual designs. eWise can design reliable mobile app design services to create custom application designs that amplify your user engagement.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Mobile Prototype Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Do you have a dream project that requires an Android mobile app or need an iOS app mockup designed? We’ve got your back! We can get you started right away by designing a mobile app prototype for your projects.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Responsive Web Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">In the ‘mobile first’ world, businesses must make sure their web designs work on every design. eWise with the expert team, will enable you to adapt to every mobile device and optimize your users’ experience.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">e-Commerce Web Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Set up your online store and get your business sales boosted to the next-level. eWise develops e-commerce stores with the help of the latest technologies that will help you establish strong visibility in the digital world.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Cross Platform Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise enables businesses to scale across different mobile platforms together through our focused cross platform mobile development process. eWise’s team of professional developers helps organizations in improving user engagement across various mobile platforms.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Mobile Game Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise ensures that your game/concept is not just adaptable to a single platform. We try to cover a wide range of devices for maximum reach to the audience. Our Android, iPhone and Windows Mobile game developers/designers are well skilled to design graphics or write a code that can be easily ported to any platform.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Product Branding Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">From brand strategy to brand guidelines, eWise can help you to create it for your product. Our designers will help you to craft on the front end to establish a set of design principles that impact every angle of your presence, service and delivery.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Logo Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">WE TURN YOUR VISION INTO REALITY! At eWise, our team of designers and marketers work with you from day one to turn their vision into a reality. Through continuous consultation and improvisation during the design process, the perfect business logo can be created. Having the perfect logo for your company will enable you to achieve the next marketing level, knowing that you have a brand image that is best for both online and print marketing use.</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap  pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why hire eWise as a graphic design expert<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="px-4 pb-4">eWise has worked with clients from various industries, and hence knows how each of it works. It gives us an upper-hand to create a design that will go with a particular industry vertical, yet set the brand apart from the rest of the competition. eWise has a team of designers who have deep understanding on how a design can impact the target audience.</p>
                    <p className="px-4 pb-4">Our process-centric execution of design projects enables clients to be aware of each stage of design and helps them to suggest at every step, right from requirement gathering to quality check. This makes sure you go with a perfect graphic design that is coherent with your brand.</p>
                    <p className="px-4">eWise promises you to deliver your projects on time, so you meet your business goals within your deadlines. We do not compromise on your dream designs, and assure you the best in the market!</p>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>
    </Layout>
  )
}

export default GraphicDesign